.container .submitButton {
  padding-top: 1.5rem;
  text-align: center;
}

.container .input {
  width: 100%;
}

.container {
  margin: auto;
  width: fit-content;
}

.container .radioButtonWrapper {
  padding-top: 1.5rem;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.customFlexBox {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
