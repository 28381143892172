.line {
  display: flex;
}
.line > * {
  margin-right: 1.5rem;
}
.pageRow {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
p > a {
  color: white;
  text-decoration: underline;
  text-underline-position: auto;
}
