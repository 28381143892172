.divWrapper {
  height: 100%;
}

.pageWrapper {
  background-image: url("hackathon_docs_logo_clipped.svg");
  background-color: #1c2640;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
  min-height: 20%;
}

.spanWrapper {
  display: flex;
  flex-wrap: wrap;
}

.refresherWrapper {
  background-color: #27314a;
  padding: 18px;
  display: flex;
}
