.modal {
}
.modalDiv {
  margin: auto;
  display: grid;
}
.lockIcon {
  color: pink;
  margin: auto;
}
.heading {
  /* Desktop/Heading Small (600) */
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  /* identical to box height, or 120% */
  margin: auto;
  display: flex;
  align-items: center;
  text-align: center;
  /* Dark Blue */
  color: #1c2640;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input {
  margin: auto;
  display: flex;
}
.button {
  margin: auto;
  display: flex;
}
