.panelWrapper .panel {
  min-width: 18rem;
}

@media screen and (min-width: 768px) {
  .panelWrapper .panel {
    width: 100%;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    background-color: #f5f5f2;
  }
}

.panelWrapper .panelText {
  padding-bottom: 2.4rem;
}
