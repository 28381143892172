.container .button {
  display: inline-block;
  vertical-align: bottom;
  height: 4.8rem;
  width: 4.8rem;
  min-height: 4.8rem;
  min-width: 4.8rem;
  padding: 0;
}
.container .input {
  display: inline-block;
  min-width: 0;
  margin-top: 0;
}
.container {
  margin: auto;
  width: fit-content;
  margin-top: 0.8rem;
}
