.card {
  background-color: #27314a;
  padding: 5em 2.5em 5em 2.5em;
}

.questionMark {
  background-color: #ffe433;
  border-radius: 100%;
  padding: 1em;
}
